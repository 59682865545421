<template>
  <table-view
    class="leave-out"
    :total="total"
    :query-info.sync="queryInfo"
    v-loading="loading"
    @reload-table="renderTable"
  >
    <template #header>
      <view-search-form
        keyword-placeholder="学生名"
        :queryInfo.sync="queryInfo"
        :tool-list="['keyword']"
        :filter-keys="['filterType']"
        :show-clear-btn="false"
        style="display:inline-block"
        @on-search="renderTable(1)">
        <el-form-item prop="leaveStatus"
                      label="审核状态">
          <el-select
            v-model="queryInfo.leaveStatus"
            clearable
            size="small"
            placeholder="审核状态"
            style="width: 100px;">
            <el-option v-for="item in $store.state.systemParam.reviewStatus" :key="item.paramValue"
                       :value="item.paramValue" :label="item.label" />
          </el-select>
        </el-form-item>
        <template v-for="item in Object.values(filterTypeMap)">
          <el-form-item :key="item.value"
                        label-width="110px"
                        v-if="$store.state.login.isAdmin|| item.key===currentAuditRole.key"
                        :prop="item.statusKey"
                        :label="item.label+'审核'">
            <el-select
              v-model="queryInfo[item.statusKey]"
              clearable
              size="small"
              placeholder="请选择审核状态">
              <el-option :value="1" label="通过" />
              <el-option :value="0" label="不通过" />
            </el-select>
          </el-form-item>
        </template>
      </view-search-form>
      <div class="header-button fr">
        <template v-if="batchCheckFlag">
          <el-button size="small" type="success" @click="handleBathCheck('pass')">批量通过</el-button>
          <el-button size="small" type="danger" plain @click="handleBathCheck('reject')">批量驳回</el-button>
        </template>
        <el-button size="small" :type="batchCheckFlag?'':'primary'" @click="handleBathCheck()">
          {{ batchCheckFlag ? '关闭' : '开启' }}批量审批
        </el-button>
        <el-button size="small" type="success" plain @click="exportMultipleForm()">批量导出请假单</el-button>
      </div>
    </template>
    <!-- body -->
    <el-table ref="tableRef" :data="tableData" style="width: 100%" border stripe
              @selection-change="handleSelectChange">
      <el-table-column type="selection" width="50" align="center"
                       :selectable="handleRowSelectable" />
      <el-table-column type="index" label="ID" width="50" />
      <el-table-column prop="stuName" label="学生姓名" width="100" align="center" />
      <el-table-column prop="grade" label="年级" width="100" align="center" />
      <el-table-column prop="classesName" label="班级" min-width="200" align="center" />
      <el-table-column prop="leaveReason" label="请假原因" min-width="240px" />
      <el-table-column prop="leaveReason" label="请假时段" width="290" align="center">
        <template v-slot="{ row }">
          <span>{{ row.beginTime|formatDate('YYYY-MM-DD HH:mm') }} 至 {{ row.endTime|formatDate('YYYY-MM-DD HH:mm')
            }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="timeLong" label="请假时长(天)" width="110" align="center" />
      <el-table-column prop="schStatus" label="审核状态" width="80">
        <template v-slot="{ row }">
          <el-tag v-if="row.leaveStatus" :type="reviewStatusMap[row.leaveStatus].btnType">
            {{ reviewStatusMap[row.leaveStatus].label }}
          </el-tag>
          <el-tag v-else type="info">
            已取消
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="schStatus" label="过程" width="140px" align="center">
        <template v-slot="{ row }">
          <span v-html="parseProcess(row)"></span>
        </template>
      </el-table-column>
      <el-table-column label="附件" width="170">
        <template v-slot="{ row }">
          <div class="image" v-if="row.imgUrlList">
            <el-image v-for="(imgUrl, index) in row.imgUrlList" :key="index"
                      style="width: 100%; height: auto; margin-right: 6px" :src="`${$fileUrl}${imgUrl}`"
                      :preview-src-list="[`${$fileUrl}${imgUrl}`]" fit="contain" />
          </div>
        </template>
      </el-table-column>
      <el-table-column label="销假情况" width="90" align="center" fixed="right">
        <template v-slot="{ row }">
          {{ row.verifyFlag === 1 ? '已销假' : '' }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="300" fixed="right">
        <template v-slot="{ row }">
          <!--          v-permission="auditPermitKeys"-->
          <span class="mr-1" v-permission="auditPermitKeys">
            <el-button type="primary"
                       v-if="row.leaveStatus"
                       @click="showDialog(row.id,row)">{{ getPermitStatus(row) ? '审批' : '打印预览' }}
            </el-button>
            <el-button
              type="danger" size="mini"
              v-if="getCancelBtn(row)"
              @click="cancelReview(row)">撤回审批
            </el-button>
          </span>
          <span v-permission="['leaveAudit:teacher']">
            <el-button type="danger"
                       v-if="row.leaveStatus===3&&!row.verifyFlag"
                       @click="showSubDialog('visibleVerifyDialog','verifyDialogRef',row.id, row)"> 销假
            </el-button>
          </span>
          <el-button type="primary"
                     v-if="row.leaveStatus"
                     @click="exportSingleForm(row)">下载
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--  请假审批 dialog  -->
    <addLeaveDialog ref="dialogRef"
                    :visible.sync="visibleDialog"
                    v-if="visibleDialog"
                    :filter-type="queryInfo.filterType"
                    :filter-type-map="filterTypeMap"
                    @on-close="renderTable" />
    <verify-leave-dialog ref="verifyDialogRef" :visible.sync="visibleVerifyDialog" v-if="visibleVerifyDialog"
                         @on-close="renderTable" />
  </table-view>
</template>

<script>
import { getLeaveList, deleteLeave, cancelReviewLeaveApi, bathApproveApi } from '@/api/leave'
import addLeaveDialog from './component/addLeave.vue'
import store from '@/store'
import tableView from '@/vue/mixins/table-view'
import { throttle } from 'lodash'
import VerifyLeaveDialog from '@/views/pages/secondCollege/leave-out/component/verifyLeaveDialog.vue'

export default {
  components: {
    VerifyLeaveDialog,
    addLeaveDialog
  },
  mixins: [tableView],
  data() {
    return {
      visibleVerifyDialog: false, // 销假dialog
      isExporting: false, // 是否导出状态
      reviewStatusMap: {}, // 审核状态树
      queryInfo: {
        filterType: 0, // 过滤类型：1-辅导员（所有） 2-院领导（2天以上） 3-学生处（5天及以上） 4-校领导（7天以上
        // schStatus: null, // 校领导审核 1 通告 0 不通过
        collegeStatus: null, // 院领导审核 1 通告 0 不通过
        leaveStatus: null, // 请假单状态 1 审核中 2 审核不通过 3 通过
        stuOfficeStatus: null, // 学生处审核 1 通告 0 不通过
        teacherStatus: null, // 辅导员审核 1 通告 0 不通过
        userId: null,
        timeLong: 0 // 申请时长
      },
      auditPermitKeys: ['leaveAudit:teacher', 'leaveAudit:college', 'leaveAudit:stuOffice', 'leaveAudit:sch'], // 审批权限
      filterTypeMap: {
        1: {
          value: 1,
          label: '辅导员',
          key: 'teacherUserId',
          statusKey: 'teacherStatus',
          permitKey: 'leaveAudit:teacher',
          auditPermitKeys: ['collegeUserId', 'stuOfficeUserId', 'schUserId']
        },
        2: {
          value: 2,
          label: '院领导',
          key: 'collegeUserId',
          statusKey: 'collegeStatus',
          permitKey: 'leaveAudit:college',
          auditPermitKeys: ['stuOfficeUserId', 'schUserId']
        },
        3: {
          value: 3,
          key: 'stuOfficeUserId',
          statusKey: 'stuOfficeStatus',
          permitKey: 'leaveAudit:stuOffice',
          label: '学生处',
          auditPermitKeys: ['schUserId']
        },
        4: {
          value: 4,
          label: '校领导',
          key: 'schUserId',
          statusKey: 'schStatus',
          permitKey: 'leaveAudit:sch',
          auditPermitKeys: []
        }
      },
      currentAuditRole: null, // 当前用户审批角色
      batchCheckFlag: false, // 是否开启批量审批
      leaveList: [],
      rowCheckList: [] // table 选中的
    }
  },
  created() {
    // 保存当前用户审批角色
    this.currentAuditRole = Object.values(this.filterTypeMap).find((item) => store.getters['login/getPermission'].includes(item.permitKey))

    this.reviewStatusMap = this.$store.state.systemParam.reviewStatus.reduce((prev, current) => {
      prev[current.paramValue] = current
      return prev
    }, {})
    this.queryInfo.filterType = this.currentAuditRole?.value ?? 0
    this.renderTable(1)
  },
  methods: {
    deleteLeave, // 删除api
    // 获取外出请假列表
    async renderTable(pageNum) {
      /* 先进行角色判断获取filterType,再请求数据
      */
      if (!(this.queryInfo.filterType || this.$store.state.login.isAdmin)) return
      await this.getTableData(getLeaveList, pageNum)
    },
    // 是否开启批量审批
    handleBathCheck(flag) {
      if (!flag) {
        this.batchCheckFlag = !this.batchCheckFlag
        this.$refs.tableRef.clearSelection()
        this.rowCheckList = []
        return
      }
      if (!this.rowCheckList.length) {
        this.$message.warning('请先勾选需要批量审批的数据！')
        return
      }
      // 批量通过
      let _msg
      let _status = 0
      let _type = 'error'
      if (flag === 'pass') {
        _msg = '批量通过'
        _status = 1
        _type = 'success'
      } else {
        _msg = '批量驳回'
      }
      this.$confirm(`是否确认${_msg}选中的${this.rowCheckList.length}张请假单？`, '批量审批').then(async () => {
        this.loading = true
        const _formData = {
          ids: this.rowCheckList
        }
        if (this.queryInfo.filterType) {
          _formData[this.filterTypeMap[this.queryInfo.filterType].statusKey] = _status
        }
        try {
          await bathApproveApi(_formData)
          this.$message({
            message: `已${_msg}!`,
            type: _type
          })
          await this.renderTable()
        } catch (e) {
        } finally {
          this.loading = false
        }
      })
    },
    // table checkbox控制,只能选择待审批的单子
    handleRowSelectable(row) {
      if (!this.batchCheckFlag || !this.queryInfo.filterType) return true
      return !row[this.filterTypeMap[this.queryInfo.filterType].key]
    },
    // table 多选控制,获取选中的id
    handleSelectChange(val) {
      while (this.rowCheckList.length) {
        this.rowCheckList.pop()
      }
      if (val.length) {
        val.forEach(({ id }) => {
          this.rowCheckList.push(id)
        })
      }
    },
    // 批量导出多个请假单
    async exportMultipleForm() {
      if (!this.rowCheckList.length) {
        this.$message.warning('请先点击表格最左侧的多选框，勾选需要导出的请假单！')
        return
      }
      this.loading = true
      try {
        await this.$http.exportExcelHttp.exportMultipleLeaveOut({ ids: this.rowCheckList }, '批量导出的请假单')
        this.$message.success('批量导出成功,文件正在下载！')
        // 导出开始后，清空选中
        this.$refs.tableRef.clearSelection()
        while (this.rowCheckList.length) {
          this.rowCheckList.pop()
        }
      } catch (e) {
        return e
      } finally {
        this.loading = false
      }
    },
    // 导出单个请假单
    async exportSingleForm(row) {
      this.loading = true
      try {
        const _fileName = `${row.stuName}（${row.grade}级${row.classesName}）的请假单`
        await this.$http.exportExcelHttp.exportSingleLeaveOut(row.id, _fileName)
        this.$message.success('导出成功,文件正在下载！')
      } catch (e) {
        return e
      } finally {
        this.loading = false
      }
    },
    // 拼接审核过程
    parseProcess(row) {
      /* 天数在2天或以下：辅导员 * 天数在2天-5天:院领导 * 数是 5天以上：学生处 */
      const _processList = [
        { threshold: 0, title: '辅导员', statusKey: 'teacherStatus' },
        { threshold: 2, title: '院领导', statusKey: 'collegeStatus' },
        { threshold: 5, title: '学生处', statusKey: 'stuOfficeStatus' }
        // { threshold: 7, title: '校领导', statusKey: 'schStatus' }
      ]
      const _processRows = []
      for (const { threshold, title, statusKey } of _processList) {
        if (row.timeLong >= threshold) {
          const status = this.parseStatusToStr(row[statusKey])
          if (status !== undefined && status !== null) {
            _processRows.push(`<tr><td>${title}：</td><td>${status}</td></tr>`)
          }
        }
      }
      if (row.timeLong > 7 && row.schStatus) {
        const _schStatus = this.parseStatusToStr(row.schStatus)
        _processRows.push(`<tr><td>校领导：</td><td>${_schStatus}</td></tr>`)
      }
      return `<table class="process-table">${_processRows.join('')}</table>`
    },
    // 判断该账号是否已审核该申请
    getPermitStatus(row) {
      let _flag = false
      if (this.currentAuditRole && this.queryInfo.filterType) {
        _flag = !row[this.filterTypeMap[this.queryInfo.filterType].key]
      }
      return _flag
    },
    // 审核状态转换
    parseStatusToStr(status) {
      return status === null ? '<span class="font-grey">未审核</span>' : (Number(status) === 1 ? '<span style="color: var(--color-success)">通过</span>' : '<span style="color: var(--color-danger)">不通过</span>')
    },
    // 判断是否出现撤回审核按钮（只有在下一级未审批之前可撤回）
    getCancelBtn(row) {
      // 1. 判断是否拥有审批权限以及未销假状态
      if (!row.verifyFlag) {
        if (this.currentAuditRole) {
          return !!row[this.currentAuditRole.key] && this.currentAuditRole.auditPermitKeys.every((key) => !row[key])
        }
      }
      return false
    },
    // 撤销审批状态为‘待审批’(下一级未审批之前可撤回)
    cancelReview: throttle(function(row) {
      // 当您撤回审批后，之后的所有审批流程将重置至"待审批"状态，以便您重新进入审批流程。请再次确认您是否要撤回对学生赖嘉惠的请假审批。
      this.$confirm(`<p class="confirm-message">请确认是否<b>撤回</b>学生-<b class="danger">${row.stuName}(${row.grade}级${row.classesName})</b>的请假审批，重新进入审批流程？</p>`, '撤回请假审批', { dangerouslyUseHTMLString: true }).then(async () => {
        try {
          await cancelReviewLeaveApi(row.id)
          this.$message.success(`学生-${row.stuName}的审批撤回成功！`)
        } catch (e) {
          if (!e) {
            this.$message.error('撤回失败，请重试！')
          }
        } finally {
          await this.renderTable(1)
        }
      })
    }, 1500)
  }
}
</script>
<style lang="scss">
.leave-out {
  .process-table {
    float: right;

    tr {
      background: transparent;

      td:first-child {
        text-align: right;
      }
    }
  }
}
</style>
