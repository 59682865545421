<template>
  <div class="review-area">
    <div v-if="formData[userName]" class="flex flex-column read-only-box">
      <div>{{ title }}
        <el-tag :type="formData[status]===0?'danger':(formData[status]===1?'success':'info')">
          {{ getReviewName(formData[status]) }}
        </el-tag>
      </div>
      <div class="flex-1"> {{ formData[opinion] }}</div>
      <div class="font-right" style="margin-right: 53px">签名： {{ formData[userName] }}</div>
      <div class="font-right signature-date"> {{ formData[signTime] | formatDate('YYYY 年 MM 月 DD 日 HH:mm:ss') }}
      </div>
    </div>
    <ul v-else>
      <li>{{ title }}
        <span style="font-weight: bold">审核结果：</span>
        <el-form-item :prop="status">
          <el-select v-model="formData[status]" :disabled="formData.filterType !== filterType" size="mini"
                     style="width:100px;">
            <el-option :value="0" label="不通过" />
            <el-option :value="1" label="通过" />
          </el-select>
        </el-form-item>
      </li>
      <el-form-item :prop="opinion">
      <el-input type="textarea" rows="3" style="height: 100px;width: 100%"
                v-model="formData[opinion]"
                :disabled="formData.filterType !== filterType" />
      </el-form-item>
      <li class="font-right" v-if="formData.filterType===filterType">
        <div style="margin-right: 53px">签名： {{ $store.state.login.userInfo.realName }}</div>
        <span
          style="margin-right: 10px">
          {{ $moment().format('YYYY 年 MM 月 DD 日')}}</span>
      </li>
      <li class="font-right" v-else>
        <div style="margin-right: 100px">签名：</div>
        <span
          style="margin-right: 10px"><span
          style="margin-left: 4rem;"></span>年&#12288;&#12288;月&#12288;&#12288;日</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'reviewArea',
  props: {
    title: String,
    status: String, // 审核结果
    opinion: String, // 审核意见
    userName: String, // 审核人
    signTime: String, // 审核时间
    filterType: Number, // 判断是否为当前角色审批
    formData: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {}
  },
  methods: {
    getReviewName (status) {
      if (status === 0 || status === '0') {
        return '审批不通过'
      } else if (status === 1 || status === '1') {
        return '审批通过'
      } else {
        return '待审批'
      }
    }
  }
}
</script>

<style lang="scss">
.review-area {
  .read-only-box {
    padding: 10px 0;
    height: 261px;
  }
}
</style>
