<template>
  <table class="table leave-temp" style="width: 100%;">
    <colgroup>
      <col style="width:120px;">
      <col>
      <col style="width:120px;">
      <col>
      <col style="width:120px;">
      <col>
    </colgroup>
    <tr>
      <td class="font-right" style="width: 120px;">辅导员审批</td>
      <td colspan="5">
        与家长联系情况；是否同意其子（女）出校园；是否有相关证明材料。
        <review-area
          :form-data="formData"
          :filter-type="1"
          opinion="teacherOpinion"
          status="teacherStatus"
          user-name="teacherUserName"
          sign-time="teacherTime"
        />
      </td>
    </tr>
    <tr>
      <td colspan="3">
        <review-area
          title="副院长审批："
          :form-data="formData"
          :filter-type="2"
          status="collegeStatus"
          opinion="collegeOpinion"
          user-name="collegeUserName"
          sign-time="collegeTime"
        />
      </td>
      <td colspan="3">
        <review-area
          title="学生处审批："
          :form-data="formData"
          :filter-type="3"
          opinion="stuOfficeOpinion"
          status="stuOfficeStatus"
          user-name="stuOfficeUserName"
          sign-time="stuOfficeTime"
        />
      </td>
<!--      <td colspan="2">-->
<!--        <review-area-->
<!--          title="校领导审批："-->
<!--          :form-data="formData"-->
<!--          :filter-type="4"-->
<!--          :opinion="'schOpinion'"-->
<!--          :status="'schStatus'"-->
<!--          :user-name="'schUserName'"-->
<!--          :sign-time="'schTime'"-->
<!--        />-->
<!--      </td>-->
    </tr>
    <tr>
      <td style="text-align: right">销假时间</td>
      <td colspan="2">
        <span style="margin-right: 10px;">&#12288;&#12288;&#12288;&#12288;年&#12288;&#12288;月&#12288;&#12288;日&#12288;&#12288;时</span>
      </td>
      <td style="text-align: right;width:120px;">辅导员</td>
      <td colspan="2">
      </td>
    </tr>
  </table>
</template>

<script>

import dialog from '@/vue/mixins/dialog'
import ReviewArea from '@/views/pages/secondCollege/leave-out/component/reviewArea.vue'
import { Decimal } from 'decimal.js'

export default {
  components: { ReviewArea },
  mixins: [dialog],
  props: {
    formData: {
      type: Object,
      default: () => ({
        teacherStatus: null,
        schStatus: null,
        filterType: 0
      })
    }
  },
  data () {
    return {}
  },
  methods: {
    Decimal
  }
}
</script>
